import React, { useState, useEffect } from 'react';
import {
  Button,
  TextInput,
  TextArea,
  Modal,
  Tile,
} from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const CustomApiIntegrationPage = () => {
  const navigate = useNavigate();
  const [apis, setApis] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiName, setApiName] = useState('');
  const [apiSpec, setApiSpec] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Fetch existing APIs from the backend
    fetch('/api/custom-api-integration/list')
      .then((response) => response.json())
      .then((data) => {
        setApis(data.apis || []);
      })
      .catch((error) => {
        console.error('Error fetching APIs:', error);
      });
  }, []);

  const handleAddApi = () => {
    setApiName('');
    setApiSpec('');
    setErrorMessage('');
    setIsModalOpen(true);
  };

  const handleSaveApi = () => {
    if (!apiName || !apiSpec) {
      setErrorMessage('Please provide both API name and specification.');
      return;
    }

    // Validate API spec (optional: implement actual validation)

    // Save the API to the backend
    fetch('/api/custom-api-integration/add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: apiName, spec: apiSpec }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to save API.');
        }
        return response.json();
      })
      .then((data) => {
        setApis([...apis, data.api]);
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error('Error saving API:', error);
        setErrorMessage('An error occurred while saving your API.');
      });
  };

  const handleDeleteApi = (apiId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this API? This action cannot be undone.'
      )
    ) {
      fetch(`/api/custom-api-integration/delete/${apiId}`, {
        method: 'DELETE',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to delete API.');
          }
          setApis(apis.filter((api) => api.id !== apiId));
        })
        .catch((error) => {
          console.error('Error deleting API:', error);
          alert('An error occurred while deleting the API.');
        });
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="custom-api-integration-page">
      <div className="custom-api-integration-container">
        <h1>Custom API Integrations</h1>
        <p>
          Add your custom REST APIs by entering their OpenAPI JSON or YAML specifications.
          This allows Fresh Focus AI to interact with your APIs upon request.
        </p>
        <Button kind="primary" onClick={handleAddApi}>
          Add New API
        </Button>

        <div className="api-list">
          {apis.length > 0 ? (
            apis.map((api) => (
              <Tile key={api.id} className="api-tile">
                <h3>{api.name}</h3>
                <p>API Specification:</p>
                <pre className="api-spec">{api.spec}</pre>
                <Button
                  kind="danger--tertiary"
                  onClick={() => handleDeleteApi(api.id)}
                  size="sm"
                >
                  Delete API
                </Button>
              </Tile>
            ))
          ) : (
            <p>No custom APIs added yet.</p>
          )}
        </div>

        <Modal
          open={isModalOpen}
          modalHeading="Add Custom API"
          primaryButtonText="Save"
          secondaryButtonText="Cancel"
          onRequestClose={() => setIsModalOpen(false)}
          onRequestSubmit={handleSaveApi}
        >
          <TextInput
            id="api-name"
            labelText="API Name"
            placeholder="Enter a name for your API"
            value={apiName}
            onChange={(e) => setApiName(e.target.value)}
            required
          />
          <TextArea
            id="api-spec"
            labelText="API Specification (JSON or YAML)"
            placeholder="Paste your OpenAPI specification here"
            value={apiSpec}
            onChange={(e) => setApiSpec(e.target.value)}
            rows={10}
            required
          />
          {errorMessage && (
            <div className="error-message">{errorMessage}</div>
          )}
        </Modal>

        <div className="button-group" style={{ marginTop: '2rem' }}>
          <Button kind="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomApiIntegrationPage;
