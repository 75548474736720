// ConversationHistory.js

import React, { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionItem,
    Loading
  } from '@carbon/react';
import ReactMarkdown from 'react-markdown';

import {
Renew,
Chat,
TrashCan
  } from '@carbon/react/icons';

const ConversationHistory = ({ isActive, token, onRestoreConversation }) => {
    const [conversations, setConversations] = useState([]);
    const [openItems, setOpenItems] = useState([]); // State to track open accordion items
    const [loading, setLoading] = useState(false); // State for loading indicator

    const restoreConversation = async (event, conversation_id) => {
        try {
            event.stopPropagation(); // Prevents event from reaching the accordion header
            const response = await fetch(`/mlapi/basic/conversation/${conversation_id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` // Add token to the request header
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
    
            // Transform the conversation data to match the expected format
            let formattedHistory = [];
            for (let i = 0; i < data.conversation.length; i += 2) {
                // Assuming even indices are user messages and odd indices are system responses
                formattedHistory.push({
                    question: data.conversation[i]?.content,
                    answer: data.conversation[i + 1]?.content
                });
            }
    
            onRestoreConversation(conversation_id, formattedHistory);
        } catch (error) {
            console.error('Error fetching conversation details:', error);
        }
    };

    const fetchInitialQuestionsPersistent = async () => {
        try {
            setLoading(true); // Start loading
            // Include the content_type parameter in the query string
            const url = '/mlapi/basic/initial-questions?content_type=ai_conversation';
            
            const response = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` // Add token to the request header
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setConversations(data.map(question => ({
                ...question,
                content: null, // Initialize content as null
            })).reverse());  // Reversing the order after mapping the data
            setLoading(false); // Stop loading
        } catch (error) {
            console.error('Error fetching initial questions:', error);
            setLoading(false); // Stop loading on error
        }
    };
    

    useEffect(() => {
        const fetchInitialQuestions = async () => {
            try {
                setLoading(true); // Start loading
                const response = await fetch('/mlapi/basic/initial-questions?content_type=ai_conversation', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}` // Add token to the request header
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setConversations(data.map(question => ({
                    ...question,
                    content: null, // Initialize content as null
                })).reverse());  // Reversing the order after mapping the data
                setLoading(false); // Stop loading
            } catch (error) {
                console.error('Error fetching initial questions:', error);
                setLoading(false); // Stop loading on error
            }
        };
        console.info(`isActive: {isActive}`)
        if (isActive && token) {
            fetchInitialQuestions();
        } else {
            // Clear conversations if token is null or undefined (i.e., user logged out)
            setConversations([]);
            setOpenItems([]);
        }

    }, [isActive, token]);


    const handleAccordionChange = async (conversation_id) => {
        // Fetch conversation details when an accordion item is expanded
        try {
            const response = await fetch(`/mlapi/basic/conversation/${conversation_id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` // Add token to the request header
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setConversations(conversations.map(conv => 
                conv.conversation_id === conversation_id ? { ...conv, content: data.conversation } : conv
            ));
            // Manage open items
            const isOpen = openItems.includes(conversation_id);
            setOpenItems(isOpen ? openItems.filter(id => id !== conversation_id) : [...openItems, conversation_id]);

        } catch (error) {
            console.error('Error fetching conversation details:', error);
        }
    };
    
    const refreshConversations = () => {
        fetchInitialQuestionsPersistent();
        // Reset the content of each conversation
        setConversations(conversations.map(conv => ({
            ...conv,
            content: null
        })));
        setOpenItems([]); // Close all accordion items
    };

    const deleteConversation = async (event, conversation_id) => {
        event.stopPropagation(); // Prevents event from reaching the accordion header
        if (window.confirm("Would you like to delete this conversation?")) {
            try {
                const response = await fetch(`/mlapi/basic/conversation/${conversation_id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error("Conversation not found.");
                    } else if (response.status === 500) {
                        throw new Error("Server error occurred.");
                    } else {
                        throw new Error("An error occurred.");
                    }
                }
    
                // Delay the refresh to ensure backend has processed the deletion
                setTimeout(() => {
                    fetchInitialQuestionsPersistent();
                    setOpenItems([]);
                }, 1000); // 1000 milliseconds delay
    
            } catch (error) {
                console.error(error.message);
                alert(error.message); // Display error to the user
            }
        }
    };

    function truncateToTwoSentences(text) {
        if (text.length < 420) {
            var hasMarkup = /[<>{}[\];]/; 
            var idx = text.search(hasMarkup);
            if (idx > 160) {
                return text.substr(0, idx) + '...';
             }
        }

        const sentences = text.match(/[^.!?]+[.!?]+/g);
    
        return (sentences && sentences.length > 2)
            ? sentences.slice(0, 2).join(' ') + '...'
            : text;
    }

    const formatContent = (content) => {
        return content.map((item, index) => {
            var formattedContent = ''
            if (item.role === 'user') {
               formattedContent = '**' + item.content + '** \n\n---\n\n'
            } else {
               formattedContent = item.content;
            }
    
            // Add a separator after each answer, but not after the last item
            if (item.role === 'system' && index < content.length - 1) {
                formattedContent += '\n\n---\n\n';
            }
    
            return formattedContent;
        }).join('\n\n');
    };

    return (
        <div className="accordion-top-space">
            <h4>Conversation History &nbsp; &nbsp;
            {token && (
                <button onClick={refreshConversations} title="Refresh Conversations" style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                    <Renew size={18} />
                </button>
            )}
            </h4>
            {loading ? (
                <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            marginTop: '3em', // Adds approximately three blank lines of space
                          }}>
                    <Loading active withOverlay={false} />
                </div>
            ) : (
                <Accordion>
                    {conversations.map(({ conversation_id, initial_question, content }) => (
                        <AccordionItem
                            key={conversation_id}
                            title={
                                <>
                                <Chat 
                                    onClick={(e) => restoreConversation(e, conversation_id)} 
                                    style={{ float: 'right', cursor: 'pointer', marginRight: '25px'}} 
                                    title="Restore conversation to the AI Assistant tab" // Tooltip text
                />
                                    <TrashCan title="Delete this conversation" onClick={(e) => deleteConversation(e, conversation_id)} style={{ float: 'right', cursor: 'pointer', marginRight: '25px'}} />
                                    {truncateToTwoSentences(initial_question)}
                                </>
                            }
                            open={openItems.includes(conversation_id)}
                            onHeadingClick={() => handleAccordionChange(conversation_id)}
                        >
                        <div className={`llm-response-area`}>
                            {content
                                ? <ReactMarkdown>{formatContent(content)}</ReactMarkdown>
                                : <p style={{paddingTop: '40em', paddingBottom: '40em'}}></p>}
                        </div>
                        </AccordionItem>
                    ))}
                </Accordion>
            )}
        </div>
    );
};


export default ConversationHistory;
