// Insights.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Accordion, AccordionItem, Button, Tooltip } from '@carbon/react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { ArrowRight, Information, Renew, TrashCan } from '@carbon/react/icons';

import ReactMarkdown from 'react-markdown';
import JEmoji from 'emoji-toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  getInsightPrompt1,
  getInsightPrompt2,
  getInsightPrompt3,
} from './InsightPrompts.js';

const Insights = ({ token, isActive, user }) => {
  const primaryUrl = 'https://freshfocusai.com';
  //const  primaryUrl = 'http://localhost:8000';
  const hasGeneratedInsight = useRef(false); // Track if insight was already generated

  //const [currentInsightSteps, setCurrentInsightSteps] = useState([]); //State used by LLM when discussing insight
  const [currentInsightOutput, setCurrentInsightOutput] = useState([]); //State used for insight output
  const [currentInsightStatus, setCurrentInsightStatus] = useState([]); //State used for overall insight status (single string)

  const placeholderText =
    "Tell me how you like the insights I've provided and give me direction for future insights that interest you.";

  const [input, setInput] = useState(''); // Initialize input state
  const [conversationHistory, setConversationHistory] = useState([]);
  const [selectedModel, setSelectedModel] = useState('gpt-4o-mini'); // Default model
  const [response, setResponse] = useState(''); // Initialize response state
  const formattedResponse = JEmoji.shortnameToUnicode(response);
  const [conversationId, setConversationId] = useState(uuidv4()); // Initialize with a new UUID
  const [insightPhase, setInsightPhase] = useState(1); //1=clarifying phase, 2=execution phase, 3=completion phase (summary)
  const [completedInsights, setCompletedInsights] = useState([]);
  const [openItems, setOpenItems] = useState([]); // State to track open accordion items for insight conversations
  const [completedOpenItems, setCompletedOpenItems] = useState([]); // State to track open accordion items for completedInsights
  const [
    completedInsightConversations,
    setCompletedInsightConversations,
  ] = useState([]); // State to track open accordion items for completedInsights

  const handleAccordionChange = async conversation_id => {
    if (!token) {
      console.log('No token, cannot fetch conversation details');
      return;
    }
    // Fetch conversation details when an accordion item is expanded
    try {
      const response = await fetch(
        `/mlapi/basic/conversation/${conversation_id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // Assume data includes conversation, status, and output
      setCompletedInsightConversations(
        completedInsightConversations.map(conv =>
          conv.conversation_id === conversation_id
            ? { ...conv, content: data }
            : conv
        )
      );
      // Manage open items
      const isOpen = openItems.includes(conversation_id);
      setOpenItems(
        isOpen
          ? openItems.filter(id => id !== conversation_id)
          : [...openItems, conversation_id]
      );
    } catch (error) {
      console.error('Error fetching conversation details:', error);
      // Optionally update UI to show error message
    }
  };

  const refreshInsightConversations = () => {
    fetchCompletedInsightsConversationsPersistent();
    // Reset the content of each conversation
    setCompletedInsightConversations(
      completedInsightConversations.map(conv => ({
        ...conv,
        content: null,
      }))
    );
    setOpenItems([]); // Close all accordion items
  };

  const fetchCompletedInsightsConversationsPersistent = async () => {
    if (!token) {
      console.log('No token, cannot fetch insight conversations');
      return;
    }
    try {
      const url = '/mlapi/basic/initial-questions?content_type=ai_insight';

      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`, // Add token to the request header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCompletedInsightConversations(
        data
          .map(question => ({
            ...question,
            content: null, // Initialize content as null
          }))
          .reverse()
      ); // Reversing the order after mapping the data
    } catch (error) {
      console.error('Error fetching initial questions:', error);
    }
  };

  const handleInsightAccordionChange = async insight_id => {
    if (!token) {
      console.log('No token, cannot fetch insight details');
      return;
    }
    try {
      const response = await fetch(
        `/mlapi/premium/insight/index/${insight_id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Ensure token is correctly handled
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Accordion data is ');
      console.log(data);
      setCompletedInsights(
        completedInsights.map(insgt =>
          insgt.insight_id === insight_id ? { ...insgt, content: data } : insgt
        )
      );

      const isOpen = completedOpenItems.includes(insight_id);
      setCompletedOpenItems(
        isOpen
          ? completedOpenItems.filter(id => id !== insight_id)
          : [...completedOpenItems, insight_id]
      );
    } catch (error) {
      console.error('Error fetching insight details:', error);
    }
  };

  const fetchCompletedInsightsPersistent = async () => {
    if (!token) {
      console.log('No token, cannot fetch completed insights');
      return;
    }
    try {
      const url = '/mlapi/premium/insight/titles?content_type=all';

      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`, // Add token to the request header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCompletedInsights(
        data
          .map(insight => ({
            ...insight,
            content: null, // Initialize content as null
          }))
          .reverse()
      ); // Reversing the order after mapping the data
    } catch (error) {
      console.error('Error fetching initial insights:', error);
    }
  };

  //console.debug('Insight converstion id ' + conversationId);
  //console.debug('Prompt text for user: ' + placeholderText);
  const handleModelChange = e => {
    setSelectedModel(e.target.value);
  };

  const handleInputChange = e => {
    setInput(e.target.value);
  };

  const clearInsightTasks = async () => {
    if (!token) {
      console.log('No token, cannot clear insight tasks');
      return;
    }
    const deleteRequestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const getRequestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    var insightName = 'insights';
    var insightId = 'current';

    const deleteResponse = await fetch(
      '/mlapi/premium/insight/data/delete/' + insightName + '/' + insightId,
      deleteRequestOptions
    );
    if (!deleteResponse.ok) {
      console.log(
        `Bad response from deleting /mlapi/premium/insight/data/delete/${insightName}/${insightId} status: ${
          deleteResponse.status
        }`
      );
    } else {
      const deleteData = await deleteResponse.json();
      console.log(deleteData.message); // "Deleted insight data"
    }

    const deleteInsightOutputResponse = await fetch(
      '/mlapi/premium/insight/data/delete/' +
        insightName +
        '/' +
        insightId +
        '_output',
      deleteRequestOptions
    );
    if (!deleteInsightOutputResponse.ok) {
      console.log(
        `Bad response from deleting /mlapi/premium/insight/data/delete/${insightName}/${insightId}_output status: ${
          deleteInsightOutputResponse.status
        }`
      );
    } else {
      const deleteData = await deleteInsightOutputResponse.json();
      console.log(deleteData.message); // "Deleted insight output data"
    }

    const deleteInsightStatusResponse = await fetch(
      '/mlapi/premium/insight/data/delete/' +
        insightName +
        '/' +
        insightId +
        '_status',
      deleteRequestOptions
    );
    if (!deleteInsightStatusResponse.ok) {
      console.log(
        `Bad response from deleting /mlapi/premium/insight/data/delete/${insightName}/${insightId}_status status: ${
          deleteInsightStatusResponse.status
        }`
      );
    } else {
      const deleteData = await deleteInsightStatusResponse.json();
      console.log(deleteData.message); // "Deleted insight status data"
    }

    setTimeout(function() {
      fetchInsightData();
    }, 500); // 500 ms delay
  };

  const handleClearHistory = async () => {
    if (!token) {
      console.log('No token, cannot clear history');
      return;
    }
    setConversationHistory([]); // Clears the conversation history
    setResponse(''); // Optionally clear the current response display
    setConversationId(uuidv4());
    clearInsightTasks();
    setInsightPhase(1);
    //Delete conversation ID in redis
    const deleteIdRequestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        value: conversationId,
        ttl: 0,
      }),
    };

    const deleteIdResponse = await fetch(
      '/mlapi/premium/insight/data/delete/insights/current_id',
      deleteIdRequestOptions
    );
    if (!deleteIdResponse.ok) {
      throw new Error(`HTTP error! status: ${deleteIdResponse.status}`);
    }
    await deleteIdResponse.json();
  };

  const truncateToTwoSentences = text => {
    if (text.length < 420) {
      const hasMarkup = /[<>{}[\];]/;
      const idx = text.search(hasMarkup);
      if (idx > 160) {
        return text.substr(0, idx) + '...';
      }
      return text; // Make sure to return the original text if no truncation is done
    }

    const sentences = text.match(/[^.!?]+[.!?]+/g);

    return sentences && sentences.length > 2
      ? sentences.slice(0, 2).join(' ') + '...'
      : text;
  };

  const givePersonalInsightReply = async () => {
    if (!token) {
      console.log('No token, cannot give personal insight reply');
      return;
    }
    console.log('Insight phase 2, Personal reply to user');
    let systemPrompt = '';
    systemPrompt = getInsightPrompt2();
    // Determine the role based on the selected model
    const roleForAIResponse =
      selectedModel === 'gemini-pro' ? 'model' : 'system';

    let messages = [
      { role: roleForAIResponse, content: systemPrompt }, // Always add the system prompt first
    ];

    if (conversationHistory && conversationHistory.length > 0) {
      // Append the conversation history messages after the system prompt
      const historyMessages = conversationHistory.flatMap(interaction => [
        { role: 'user', content: interaction.question },
        { role: roleForAIResponse, content: interaction.answer },
      ]);
      messages = messages.concat(historyMessages);
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(messages),
    };

    setResponse('');
    setInput('');
    try {
      const response = await fetch(
        `/mlapi/basic/stream-llm?model=${selectedModel}`,
        requestOptions
      );

      if (!response.body) {
        throw new Error('Response body is not readable');
      }

      const reader = response.body.getReader();
      let receivedLength = 0;
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        const textChunk = new TextDecoder('utf-8').decode(value, {
          stream: true,
        });
        setResponse(prevResponse => prevResponse + textChunk);
      }

      // Concatenate chunks into a single string
      let chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      let result = new TextDecoder('utf-8').decode(chunksAll);

      setResponse(`\n  \n**${input}**\n  \n  ${result}`);

      // Now save in elasticsearch and set to new topic:
      setConversationHistory(prevHistory => [
        ...prevHistory,
        { question: input, answer: result },
      ]);

      const latest_messages = [
        { role: 'user', content: input },
        { role: 'system', content: result },
      ];

      try {
        const response = await updateConversation(latest_messages);
        console.log('Response from update conversation: ', response);
      } catch (error) {
        console.error('Error during API call:', error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function updateConversation(messages) {
    if (!token) {
      console.log('No token, cannot update conversation');
      return;
    }
    const url = `/mlapi/basic/conversation/${encodeURIComponent(
      conversationId
    )}/update`;

    // Prepare the payload to include messages and content_type
    const payload = {
      messages: messages,
      content_type: 'ai_insight',
      model_id: selectedModel,
      status: currentInsightStatus,
      output: currentInsightOutput,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ensure your token is accessible here
        },
        body: JSON.stringify(payload), // Send the payload as JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error during updateConversation API call:', error);
      throw error; // Re-throw the error for further handling if needed
    }
  }

  const trimQuotes = str => str.replace(/^["']|["']$/g, '');
  const fetchInsightOverallStatus = useCallback(() => {
    if (!token) {
      console.log('No token, cannot fetch insight overall status');
      return;
    }
    const insight_data_url =
      'mlapi/premium/insight/data/retrieve/insights/current_status';
    console.log('Fetching insight data from: ' + insight_data_url);
    fetch(insight_data_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          console.log('Response from current insight ' + response.status);
          return '';
        }
        return response.text();
      })
      .then(text => {
        console.log('Received insight status: ' + text);
        let currentStatus = trimQuotes(text);
        setCurrentInsightStatus(currentStatus); // Update the state with the parsed data
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch or parsing process
        console.error('Error in fetch operation or JSON parsing:', error);
      });
  }, [token]); // Include token in the dependency array

  // New function to fetch and process conversation
  const fetchAndProcessConversation = useCallback(
    async currentId => {
      if (!token) {
        console.log('No token, cannot fetch conversation');
        return;
      }
      console.log('Retrieving conv history for insight id: ' + currentId);
      try {
        const conversationResponse = await fetch(
          `/mlapi/basic/conversation/${currentId}`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`, // Use global token variable directly
            },
          }
        );

        if (!conversationResponse.ok) {
          throw new Error(`HTTP error! Status: ${conversationResponse.status}`);
        }

        const data = await conversationResponse.json();
        let formattedHistory = [];

        // Assuming even indices are user messages and odd indices are system responses
        for (let i = 0; i < data.conversation.length; i += 2) {
          formattedHistory.push({
            question: data.conversation[i]?.content,
            answer: data.conversation[i + 1]?.content,
          });
        }

        setConversationHistory(formattedHistory);
      } catch (err) {
        console.error(
          'Error fetching or processing conversation history:',
          err
        );
      }
    },
    [token]
  );

  const fetchInsightCurrentId = useCallback(async () => {
    if (!token) {
      console.log('No token, cannot fetch current insight id');
      return;
    }
    const insight_data_url =
      'mlapi/premium/insight/data/retrieve/insights/current_id';
    console.log('Fetching insight id: ' + insight_data_url);

    try {
      const response = await fetch(insight_data_url, {
        headers: {
          Authorization: `Bearer ${token}`, // Use global token variable directly
        },
      });

      if (!response.ok) {
        console.debug('Response from get id ' + response.status);
        return '';
      }

      const text = await response.text();
      console.log('Received insight id: ' + text);

      if (text && text.length > 0) {
        let currentId = trimQuotes(text);
        setConversationId(currentId);

        // Call the new method
        await fetchAndProcessConversation(currentId);
      }
    } catch (error) {
      console.error(
        'Error in fetch operation or during response handling:',
        error
      );
    }
  }, [token, fetchAndProcessConversation]); // Include token in the dependency array if it's part of a context or React state

  const fetchInsightOutput = useCallback(() => {
    if (!token) {
      console.log('No token, cannot fetch insight output');
      return;
    }
    const insight_data_url =
      'mlapi/premium/insight/data/retrieve/insights/current_output';
    console.log('Fetching insight data from: ' + insight_data_url);
    fetch(insight_data_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          console.log('Response from current insight ' + response.status);
          return '';
        }
        return response.text();
      })
      .then(text => {
        console.log('Received insight output: ' + text);

        const tryParseJson = input => {
          try {
            console.log('Insight Output: Attempting to parse:', input);
            const parsed = JSON.parse(input);
            console.log('Insight Output: Successfully parsed:', parsed);
            return parsed;
          } catch (error) {
            console.log('Insight Output: failed, returning original input.');
            return input; // Return the original input if parsing fails
          }
        };

        // Iteratively parse the content until it's no longer a string
        let contentToProcess = text;
        let iterations = 0; // Keep track of the number of parsing iterations
        while (typeof contentToProcess === 'string' && iterations < 3) {
          // Limit iterations to prevent infinite loops
          console.log(
            `Insight Output: Iteration ${iterations +
              1}, content before parsing:`,
            contentToProcess
          );
          contentToProcess = tryParseJson(contentToProcess);
          iterations++;
        }

        // After parsing, check if the final content has a 'response' property and is an object
        const isObject =
          typeof contentToProcess === 'object' && contentToProcess !== null;
        const hasResponseProperty = isObject && 'response' in contentToProcess;
        console.log(
          'Insight Output: Final content is an object:',
          isObject,
          ', Has response property:',
          hasResponseProperty
        );

        // The content is considered formatted if there's a 'response' property with string content
        const isFormattedResponse =
          hasResponseProperty && typeof contentToProcess.response === 'string';
        console.log(
          'Insight Output: isFormattedResponse: ',
          isFormattedResponse
        );

        // Function to format the response content
        const formatResponse = response =>
          typeof response === 'string'
            ? response.replace(/\\n/g, '\n')
            : response;
        let insightOutput = text;
        if (isFormattedResponse) {
          console.log('Insight Output: getting formatted.');
          insightOutput = formatResponse(contentToProcess.response);
        }

        console.log('Insight Output: ' + insightOutput);
        setCurrentInsightOutput(insightOutput); // Update the state with the parsed data
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch or parsing process
        console.error('Error in fetch operation or JSON parsing:', error);
      });
  }, [token]); // Include token in the dependency array

  const fetchInsightData = useCallback(() => {
    if (!token) {
      console.log('No token, cannot fetch insight data');
      return;
    }
    fetchInsightOverallStatus();
    fetchInsightOutput();
    fetchInsightCurrentId();
    const insight_data_url =
      'mlapi/premium/insight/data/retrieve/insights/current';
    console.log('Fetching insight data from: ' + insight_data_url);
  }, [
    fetchInsightOutput,
    fetchInsightOverallStatus,
    fetchInsightCurrentId,
    token,
  ]); // Include token in the dependency array

  const handleCreateInsight = useCallback(async () => {
    if (!token) {
      console.log('No token, cannot create insight');
      return;
    }
    const postRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    let message = 'Insight not ready to generate';

    //const toolsQueryParams = 'tools=web_search_google&tools=news_search_google&tools=web_search_tavily&tools=medical_search_pubmed&tools=reddit_search';
    const toolsQueryParams =
      '&tools=web_search_google&tools=news_search_google&tools=web_search_tavily&tools=medical_search_pubmed&tools=reddit_search&tools=scholarly_search_google&tools=finance_news_search_yahoo&tools=finance_news_search_google&tools=video_search_youtube&tools=programming_search_stackoverflow&tools=encyclopedia_search_wikipedia&tools=finance_news_search_alphavantage&tools=web_search_duckduckgo&tools=news_search_duckduckgo';
    const generateUrl = `/mlapi/premium/insight/create?model=${selectedModel}&${toolsQueryParams}&async_execution=true`; //&create_assignment_tasks=true

    const generateInsight = await fetch(generateUrl, postRequestOptions);
    if (generateInsight.ok) {
      const insightJson = await generateInsight.json();
      message = insightJson.message;
      toast.info(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      });
      console.log('Starting insight creation: ' + message);
    } else {
      console.log(
        `Error trying to start insight creation: ${generateInsight.status}`
      );
      message = 'Unable to generate insight.';
    }

    setResponse('');
    setCurrentInsightStatus(message);
  }, [token, selectedModel]); // Wrapped with useCallback

  useEffect(() => {
    if (!token) {
      setCompletedInsights([]);
      setOpenItems([]);
      setCompletedOpenItems([]);
      setConversationHistory([]);
      setCompletedInsightConversations([]);
      setConversationId('');
      setInput('');
      setResponse('');
      setCurrentInsightStatus('');
      setCurrentInsightOutput('');
      return;
    }

    const fetchCompletedInsights = async () => {
      if (!token) {
        console.log('No token, cannot fetch completed insights');
        return;
      }
      try {
        const url = '/mlapi/premium/insight/titles?content_type=all';

        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCompletedInsights(
          data
            .map(insight => ({
              ...insight,
              content: null, // Initialize content as null
            }))
            .reverse()
        ); // Reversing the order after mapping the data
      } catch (error) {
        console.error('Error fetching initial insights:', error);
      }
    };

    const fetchInsightConversations = async () => {
      if (!token) {
        console.log('No token, cannot fetch insight conversations');
        return;
      }
      try {
        const url = '/mlapi/basic/initial-questions?content_type=ai_insight';

        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCompletedInsightConversations(
          data
            .map(question => ({
              ...question,
              content: null, // Initialize content as null
            }))
            .reverse()
        ); // Reversing the order after mapping the data
      } catch (error) {
        console.error('Error fetching initial questions:', error);
      }
    };

    console.info(`isActive: {isActive}`);
    if (isActive && token) {
      fetchInsightConversations();
      fetchCompletedInsights();
      // Ensure the insight is generated only once
      if (!hasGeneratedInsight.current) {
         handleCreateInsight();
         hasGeneratedInsight.current = true; // Mark as triggered
      }
    } else {
      // Clear completedInsights if token is null or undefined (i.e., user logged out)
      setCompletedInsights([]);
      setCompletedInsightConversations([]);
      setCompletedOpenItems([]);
      setOpenItems([]);
    }

    fetchInsightData();
    var wsHost = primaryUrl;
    var envHost = process.env.REACT_APP_PUBLIC_URL;
    console.log(`envHost=${envHost}`);
    if (envHost && envHost.trim() !== '') {
      wsHost = process.env.REACT_APP_PUBLIC_URL;
      console.log(`Retrieved host ${wsHost}`);
    } else {
      console.log('No process.env found');
    }

    var httpProtocol = wsHost.split('//')[0];
    const wsProtocol = httpProtocol === 'https:' ? 'wss:' : 'ws:';
    wsHost = wsHost.split('//')[1];

    // Construct the WebSocket URL using the current host and the specific path
    var wsUrl = `${wsProtocol}//${wsHost}/mlapi/premium/insight/ws/insight_updates/${user}`;
    console.log(`Trying web socket: ${wsUrl}`);
    const ws = new WebSocket(wsUrl);

    ws.onmessage = event => {
      const data = event.data;
      //Data should be a string like "AI Insight Added"

      console.log(`Just received event data:`, data); // This will log the object structure
      toast.info(data, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      });

      if (data.includes('AI Insight Added')) {
        console.log(
          'Refreshing completed insights from websocket update alert'
        );
        fetchCompletedInsights();
      }

      fetchInsightData();
      //alert(`Just received event data: ${JSON.stringify(data)}`); // Alerts the stringified object
    };

    return () => {
      ws.close();
    };
  }, [token, user, fetchInsightData, isActive, handleCreateInsight]); // Dependency array includes handleCreateInsight

  const deleteConversation = async (event, conversation_id) => {
    if (!token) {
      console.log('No token, cannot delete conversation');
      return;
    }
    event.stopPropagation(); // Prevents event from reaching the accordion header
    if (window.confirm('Would you like to delete this conversation?')) {
      try {
        const response = await fetch(
          `/mlapi/basic/conversation/${conversation_id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Conversation not found.');
          } else if (response.status === 500) {
            throw new Error('Server error occurred.');
          } else {
            throw new Error('An error occurred.');
          }
        }

        // Delay the refresh to ensure backend has processed the deletion
        setTimeout(() => {
          fetchCompletedInsightsConversationsPersistent();
          setOpenItems([]);
        }, 1000); // 500 milliseconds delay
      } catch (error) {
        console.error(error.message);
        alert(error.message); // Display error to the user
      }
    }
  };

  const deleteInsight = async (event, insight_id) => {
    if (!token) {
      console.log('No token, cannot delete insight');
      return;
    }
    event.stopPropagation(); // Prevents event from reaching the accordion header
    if (window.confirm('Would you like to delete this insight?')) {
      try {
        const response = await fetch(
          `/mlapi/premium/insight/index/${insight_id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('insight not found.');
          } else if (response.status === 500) {
            throw new Error('Server error occurred.');
          } else {
            throw new Error('An error occurred.');
          }
        }

        // Delay the refresh to ensure backend has processed the deletion
        setTimeout(() => {
          fetchCompletedInsightsPersistent();
          setCompletedOpenItems([]);
        }, 1000); // 500 milliseconds delay
      } catch (error) {
        console.error(error.message);
        alert(error.message); // Display error to the user
      }
    }
  };

  const refreshCompletedInsights = () => {
    fetchCompletedInsightsPersistent();
    // Reset the content of each conversation
    setCompletedInsights(
      completedInsights.map(conv => ({
        ...conv,
        content: null,
      }))
    );
    setCompletedOpenItems([]); // Close all accordion items
  };

  //Save conversation ID as current in redis
  const saveCurrentConversationId = async conversationId => {
    if (!token) {
      console.log('No token, cannot save conversation ID');
      return;
    }
    const saveIdRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Use global token variable directly
      },
      body: JSON.stringify({
        value: conversationId,
        ttl: 0, // Time to live for the entry, 0 might mean no expiration
      }),
    };

    const saveIdResponse = await fetch(
      '/mlapi/premium/insight/data/save/insights/current_id',
      saveIdRequestOptions
    );
    if (!saveIdResponse.ok) {
      throw new Error(`HTTP error! status: ${saveIdResponse.status}`);
    }
    await saveIdResponse.json(); // Assuming we need to process this json response
  };

  const formatContent = content => {
    // Extract status and output from the content object
    const { conversation, status, output } = content;

    // Format the conversation content
    let formattedContent = conversation
      .map((item, index) => {
        var prefix = '';
        if (item.role === 'user') {
          prefix = '**' + item.content + '** \n\n---\n\n';
        } else {
          prefix = item.content;
        }

        // Add a separator after each answer, but not after the last item
        if (item.role === 'system' && index < conversation.length - 1) {
          prefix += '\n\n---\n\n';
        }

        return prefix;
      })
      .join('\n\n');

    // Append Elasticsearch status and output data
    if (status) {
      formattedContent += `\n\n### Status:\n${status}\n`;
    }
    if (output) {
      formattedContent += `\n\n### Output:\n${output}\n`;
    }

    return formattedContent;
  };

  const formatCompletedContent = content => {
    // Extract status and output from the content object
    const { title, status, output, timestamp } = content;
    let formattedContent = '';
    if (title) {
      formattedContent += `\n\n#### ${title}\n`;
    }
    if (status === 'complete') {
      if (timestamp) {
        formattedContent += `\n\n### Completed: ${formatDateTime(timestamp)}\n`;
      }
    } else {
      formattedContent += `\n### Status: ${status}\n`;
      formattedContent += `\n### Created: ${formatDateTime(timestamp)}\n`;
    }

    if (output) {
      formattedContent += `\n${output}\n`;
    }

    return formattedContent;
  };

  function formatDateTime(dateTimeString) {
    // Parse the date-time string to a Date object
    const date = new Date(dateTimeString);

    // Extract the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const day = String(date.getDate()).padStart(2, '0');

    // Extract the time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    hours = String(hours).padStart(2, '0');

    // Format the date and time
    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} at ${formattedTime}`;
  }

  const handleDiscuss = async () => {
    if (!token) {
      console.log('No token, cannot discuss');
      return;
    }
    const prompt = 'Please respond in Markdown. ' + input; // Construct the messages array with history and the new message

    let systemPrompt = '';
    console.log('Insight Phase: ' + insightPhase);
    if (insightPhase === 1) {
      systemPrompt = getInsightPrompt1();

      //Save conversation id as current in redis
      try {
        await saveCurrentConversationId(conversationId);
      } catch (error) {
        console.error('Failed to save conversation ID:', error);
      }
    }
    if (insightPhase === 2) {
      systemPrompt = getInsightPrompt2();
    }
    if (insightPhase === 3) {
      systemPrompt = getInsightPrompt3();
    }
    // Determine the role based on the selected model
    const roleForAIResponse =
      selectedModel === 'gemini-pro' ? 'model' : 'system';

    let messages = [
      { role: roleForAIResponse, content: systemPrompt }, // Always add the system prompt first
    ];

    if (conversationHistory && conversationHistory.length > 0) {
      // Append the conversation history messages after the system prompt
      const historyMessages = conversationHistory.flatMap(interaction => [
        { role: 'user', content: interaction.question },
        { role: roleForAIResponse, content: interaction.answer },
      ]);
      messages = messages.concat(historyMessages);
    }

    // Add the new user message
    messages.push({ role: 'user', content: prompt });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(messages),
    };

    setResponse(`\n  \n**${input}**\n  \n  `);
    setInput('');
    try {
      const response = await fetch(
        `/mlapi/basic/stream-llm?model=${selectedModel}`,
        requestOptions
      );

      if (!response.body) {
        throw new Error('Response body is not readable');
      }

      const reader = response.body.getReader();
      let receivedLength = 0;
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        const textChunk = new TextDecoder('utf-8').decode(value, {
          stream: true,
        });
        setResponse(prevResponse => prevResponse + textChunk);
      }

      // Concatenate chunks into a single string
      let chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      let result = new TextDecoder('utf-8').decode(chunksAll);
      let saveReplyInElastic = true;

      if (result && result.replace(/['\s]/g, '') === 'insightready') {
        setInsightPhase(2);
        result = '\nThank You!  I will summarize this, one second...';
        saveReplyInElastic = false;
        givePersonalInsightReply();
        setInsightPhase(1); //Go back to let user give more insight ideas
        //reviewInsightSummaryWithUser();
      }

      setResponse(`\n  \n**${input}**\n  \n  ${result}`);

      if (saveReplyInElastic) {
        setConversationHistory(prevHistory => [
          ...prevHistory,
          { question: input, answer: result },
        ]);

        const latest_messages = [
          { role: 'user', content: input },
          { role: 'system', content: result },
        ];

        try {
          const response = await updateConversation(latest_messages);
          console.log('Response from update conversation: ', response);
        } catch (error) {
          console.error('Error during API call:', error);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="accordion-top-space">
        This page offers daily personalized insights crafted by Fresh Focus AI,
        automatically generated from your previous conversations. You are also
        welcome to explore and discuss insights of particular interest to you.
      </div>
      <select
        className="llm-select"
        value={selectedModel}
        onChange={handleModelChange}>
        <option value="gpt-4o-mini">gpt-4o-mini</option>
        <option value="gpt-4o">GPT-4o</option>
        <option value="gpt-4">GPT-4</option>
        <option value="gpt-4-turbo">GPT-4-Turbo</option>
        <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
        <option value="gemini-pro">Gemini Pro</option>
        <option value="gemini-1.5-flash-latest">Gemini Flash</option>
        <option value="claude-3-opus-20240229">
          Anthropic Claude 3 Opus (best)
        </option>
        <option value="claude-3-sonnet-20240229">
          Anthropic Claude 3 Sonnet (medium)
        </option>
        <option value="claude-3-haiku-20240307">
          Anthropic Claude 3 Haiku (fast)
        </option>
        <option value="claude-2.1">Anthropic Claude 2</option>
        <option value="llama-3">Llama 3.1</option>
        <option value="cohere_command">
          Cohere Command (fast no search tools)
        </option>
        <option value="cohere_command-r">Cohere Command R (medium)</option>
        <option value="cohere_command-r-plus">
          Cohere Command R Plus (best)
        </option>
        <option value="cohere_command-nightly">
          Cohere command Nightly (latest)
        </option>
      </select>
      &nbsp;&nbsp;
      <Tooltip
        label="You can select a model to help with planning topics you are most interested in.  Review and revise the plan and when ready, hit Execute Insight"
        align="bottom">
        <button className="sb-tooltip-trigger" type="button">
          <Information />
        </button>
      </Tooltip>
      <textarea
        className="llm-request-textarea"
        placeholder={placeholderText}
        value={input}
        onChange={handleInputChange}
        onKeyDown={event => {
          // Check for Enter key without Shift
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent newline
            handleDiscuss(); // Call your submit function
          }
        }}
      />
      <Button
        className="llm-submit"
        kind="primary"
        size="small"
        onClick={handleDiscuss}>
        Discuss <ArrowRight />
      </Button>
      &nbsp;
      <Button
        className="llm-submit"
        kind="secondary"
        size="small"
        onClick={handleClearHistory}>
        Clear Discussion
      </Button>
      &nbsp;
      <Button
        className="llm-submit"
        kind="secondary"
        size="small"
        onClick={handleCreateInsight}>
        Create Insight Now
        <ArrowRight />
      </Button>
      &nbsp;
      <div className="llm-response-area">
        <ReactMarkdown>{formattedResponse}</ReactMarkdown>
      </div>
      <div className="accordion-top-space">
        <h4>
          Insights &nbsp; &nbsp;
          {token && (
            <button
              onClick={refreshCompletedInsights}
              title="Refresh Insights"
              style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
              <Renew size={18} />
            </button>
          )}
        </h4>
        <Accordion>
          {completedInsights.map(({ insight_id, title, content }) => (
            <AccordionItem
              key={insight_id}
              title={
                <>
                  <TrashCan
                    title="Delete this insight"
                    onClick={e => deleteInsight(e, insight_id)}
                    style={{
                      float: 'right',
                      cursor: 'pointer',
                      marginRight: '25px',
                    }}
                  />
                  {title}
                </>
              }
              open={completedOpenItems.includes(insight_id)}
              onHeadingClick={() => handleInsightAccordionChange(insight_id)}>
              <div className={`llm-response-area`}>
                {content ? (
                  <ReactMarkdown>
                    {formatCompletedContent(content)}
                  </ReactMarkdown>
                ) : (
                  <p style={{ paddingTop: '40em', paddingBottom: '40em' }} />
                )}
              </div>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <div className="accordion-top-space">
        {conversationHistory.length > 0 && (
          <>
            <h4>Current Insight Conversation History</h4>
            <Accordion>
              {conversationHistory.map((interaction, index) => (
                <AccordionItem
                  title={truncateToTwoSentences(`Q: ${interaction.question}`)}
                  key={index}>
                  <div className="llm-response-area">
                    <ReactMarkdown>{interaction.answer}</ReactMarkdown>
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        )}
      </div>
      <div className="accordion-top-space">
        <h4>
          Previous Insight Discussions &nbsp; &nbsp;
          {token && (
            <button
              onClick={refreshInsightConversations}
              title="Refresh Insight Conversations"
              style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
              <Renew size={18} />
            </button>
          )}
        </h4>
        <Accordion>
          {completedInsightConversations.map(
            ({ conversation_id, initial_question, content }) => (
              <AccordionItem
                key={conversation_id}
                title={
                  <>
                    <TrashCan
                      title="Delete this conversation"
                      onClick={e => deleteConversation(e, conversation_id)}
                      style={{
                        float: 'right',
                        cursor: 'pointer',
                        marginRight: '25px',
                      }}
                    />
                    {truncateToTwoSentences(initial_question)}
                  </>
                }
                open={openItems.includes(conversation_id)}
                onHeadingClick={() => handleAccordionChange(conversation_id)}>
                <div className={`llm-response-area`}>
                  {content ? (
                    <ReactMarkdown>{formatContent(content)}</ReactMarkdown>
                  ) : (
                    <p style={{ paddingTop: '40em', paddingBottom: '40em' }} />
                  )}
                </div>
              </AccordionItem>
            )
          )}
        </Accordion>
      </div>
    </>
  );
};

export default Insights;
