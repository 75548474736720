import React, { useState } from 'react';
import {
  Button,
  TextInput,
  Form,
  FormGroup,
  Grid,
  Row,
  Column,
} from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const BillingPage = () => {
  const navigate = useNavigate();
  const [billingData, setBillingData] = useState({
    nameOnCard: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, event) => {
    const { value } = event.target;

    if (fieldName === 'cardNumber') {
      const maskedValue = value
        .replace(/\D/g, '')
        .slice(0, 16)
        .replace(/(.{4})/g, '$1 ')
        .trim();
      setBillingData({ ...billingData, [fieldName]: maskedValue });
    } else if (fieldName === 'expiryDate') {
      let input = value.replace(/\D/g, '').slice(0, 4);
      if (input.length >= 3) {
        input = `${input.slice(0, 2)}/${input.slice(2)}`;
      }
      setBillingData({ ...billingData, [fieldName]: input });
    } else if (fieldName === 'cvv') {
      const maskedValue = value.replace(/\D/g, '').slice(0, 4);
      setBillingData({ ...billingData, [fieldName]: maskedValue });
    } else {
      setBillingData({ ...billingData, [fieldName]: value });
    }
  };

  const validateInputs = () => {
    const newErrors = {};

    // Validate card number
    const cardNumberDigits = billingData.cardNumber.replace(/\s+/g, '');
    if (!/^\d{16}$/.test(cardNumberDigits)) {
      newErrors.cardNumber = 'Card number must be 16 digits.';
    }

    // Validate expiry date
    if (!/^\d{2}\/\d{2}$/.test(billingData.expiryDate)) {
      newErrors.expiryDate = 'Expiry date must be in MM/YY format.';
    }

    // Validate CVV
    if (!/^\d{3,4}$/.test(billingData.cvv)) {
      newErrors.cvv = 'CVV must be 3 or 4 digits.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    // Make a POST request to your API endpoint
    fetch('/mlapi/basic/billing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(billingData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle success response
        alert('Billing information submitted successfully!');
        // Redirect or update UI as needed
      })
      .catch((error) => {
        // Handle error response
        console.error('Error:', error);
        alert('An error occurred while submitting billing information.');
      });
  };

  const handleCancelSubscription = () => {
    // Make a DELETE request to cancel the subscription
    fetch('/mlapi/basic/billing', {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle success response
        alert('Subscription canceled successfully.');
        // Redirect or update UI as needed
      })
      .catch((error) => {
        // Handle error response
        console.error('Error:', error);
        alert('An error occurred while canceling the subscription.');
      });
  };

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <div className="billing-page">
      <div className="billing-container">
        <h1>Billing</h1>
        <Form onSubmit={handleSubmit}>
          <FormGroup legendText="Credit Card Information">
            <div className="form-item">
              <TextInput
                id="nameOnCard"
                labelText="Name on Card"
                value={billingData.nameOnCard}
                onChange={(e) => handleChange('nameOnCard', e)}
                required
              />
            </div>
            <div className="form-item">
              <TextInput
                id="cardNumber"
                labelText="Card Number"
                value={billingData.cardNumber}
                onChange={(e) => handleChange('cardNumber', e)}
                required
                maxLength={19}
                placeholder="1234 5678 9012 3456"
                invalid={!!errors.cardNumber}
                invalidText={errors.cardNumber}
                style={{ width: '100%' }}
              />
            </div>
            <Grid fullWidth>
              <Row>
                <Column sm={12} md={6} lg={6}>
                  <div className="form-item">
                    <TextInput
                      id="expiryDate"
                      labelText="Expiry Date"
                      value={billingData.expiryDate}
                      onChange={(e) => handleChange('expiryDate', e)}
                      required
                      maxLength={5}
                      placeholder="MM/YY"
                      invalid={!!errors.expiryDate}
                      invalidText={errors.expiryDate}
                      style={{ width: '100%' }}
                    />
                  </div>
                </Column>
                <Column sm={12} md={6} lg={6}>
                  <div className="form-item">
                    <TextInput
                      id="cvv"
                      labelText="CVV"
                      value={billingData.cvv}
                      onChange={(e) => handleChange('cvv', e)}
                      required
                      maxLength={4}
                      placeholder="CVV"
                      invalid={!!errors.cvv}
                      invalidText={errors.cvv}
                      style={{ width: '100%' }}
                    />
                  </div>
                </Column>
              </Row>
            </Grid>
          </FormGroup>
          <div className="button-group">
            <Button type="submit">Submit Payment</Button>
            <Button kind="secondary" onClick={handleCancelSubscription}>
              Cancel Subscription
            </Button>
            <Button kind="tertiary" onClick={navigateHome}>
              Go to Home Page
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BillingPage;
