// App.js
import React, { Component } from 'react';
import './app.scss';
import FFHeader from './components/FFHeader';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Content, Theme, HeaderContainer } from '@carbon/react';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.fill';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.trim';
import 'core-js/modules/es.object.values';
import LandingPage from './content/LandingPage';
import RepoPage from './content/RepoPage';
import LoginPage from './content/LoginPage';
import BillingPage from './content/BillingPage';
import SettingsPage from './content/SettingsPage';
import PrivacyPage from './content/PrivacyPage';
import EmailIntegrationPage from './content/integrations/EmailIntegrationPage';
import SmsIntegrationPage from './content/integrations/SmsIntegrationPage';
import EvernoteIntegrationPage from './content/integrations/EvernoteIntegrationPage';
import CustomApiIntegrationPage from './content/integrations/CustomApiIntegrationPage';

import { GoogleOAuthProvider } from '@react-oauth/google';

class App extends Component {
  constructor(props) {
    super(props);
    // Initialize state from localStorage
    const token = localStorage.getItem('token');
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');
    const user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;
    const email = localStorage.getItem('email');

    let isTokenValid = true;

    // Check if the token has expired
    if (token && tokenTimestamp) {
      const tokenAge = Date.now() - parseInt(tokenTimestamp, 10);
      const maxAge = (24 * 60 - 10) * 60 * 1000; // 24 hours minus 10 minutes in milliseconds
      if (tokenAge > maxAge) {
        // Token has expired
        isTokenValid = false;
        // Remove expired token and related data
        localStorage.removeItem('token');
        localStorage.removeItem('tokenTimestamp');
        localStorage.removeItem('user');
        localStorage.removeItem('email');
      }
    }

    this.state = {
      token: isTokenValid ? token : null,
      user: isTokenValid ? user : null,
      email: isTokenValid ? email : null,
    };
  }

  componentDidMount() {
    // Check token expiration when the component mounts
    this.checkTokenExpiration();
    // Check token expiration every 10 minutes
    this.tokenCheckInterval = setInterval(
      this.checkTokenExpiration,
      10 * 60 * 1000
    ); // every 10 minutes
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts
    clearInterval(this.tokenCheckInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    // Save to localStorage when state changes
    if (prevState.token !== this.state.token) {
      if (this.state.token) {
        localStorage.setItem('token', this.state.token);
        // Save the current timestamp when the token is set
        localStorage.setItem('tokenTimestamp', Date.now().toString());
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenTimestamp');
      }
    }
    if (prevState.user !== this.state.user) {
      if (this.state.user) {
        localStorage.setItem('user', JSON.stringify(this.state.user));
      } else {
        localStorage.removeItem('user');
      }
    }
    if (prevState.email !== this.state.email) {
      if (this.state.email) {
        localStorage.setItem('email', this.state.email);
      } else {
        localStorage.removeItem('email');
      }
    }
  }

  // Method to check if the token has expired
  checkTokenExpiration = () => {
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');
    if (this.state.token && tokenTimestamp) {
      const tokenAge = Date.now() - parseInt(tokenTimestamp, 10);
      const maxAge = (24 * 60 - 10) * 60 * 1000; // 24 hours minus 10 minutes in milliseconds
      if (tokenAge > maxAge) {
        // Token has expired, log out the user
        this.handleLogout();
      }
    }
  };

  handleLogout = () => {
    // Clear the token and user information from state
    this.setState({ token: null, user: null, email: null });
    // Clear localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('tokenTimestamp');
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    // Redirect to the landing page or perform other logout tasks
    this.props.navigate('/');
  };

  setToken = (newToken, newUser, newEmail) => {
    console.log('Setting FF token to ' + newToken);
    this.setState({ token: newToken, user: newUser, email: newEmail });
    this.props.navigate('/');
  };
  //Use g100 instead of white if you want a dark theme
  render() {
    const { token, user, email } = this.state;
    return (
      <GoogleOAuthProvider clientId="929826295274-ntied11tv7jp00uggnm9ju25rt14hc1l.apps.googleusercontent.com">
        <Theme theme="white">
          <HeaderContainer
            render={({ isSideNavExpanded, onClickSideNavExpand }) => (
              <>
                <FFHeader
                  user={user}
                  email={email}
                  isLoggedIn={!!token}
                  onLogout={this.handleLogout}
                  isSideNavExpanded={isSideNavExpanded}
                  onClickSideNavExpand={onClickSideNavExpand}
                />
                <Content>
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={<LandingPage token={token} user={user} />}
                    />
                    {token ? (
                      <>
                        <Route path="/repos" element={<RepoPage />} />
                        <Route path="/billing" element={<BillingPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route
                          path="/integration/email"
                          element={<EmailIntegrationPage />}
                        />
                        <Route
                          path="/integration/sms"
                          element={
                            <SmsIntegrationPage token={token} user={user} />
                          }
                        />
                        <Route
                          path="/integration/evernote"
                          element={<EvernoteIntegrationPage />}
                        />
                        <Route
                          path="/integration/custom-api"
                          element={<CustomApiIntegrationPage />}
                        />
                      </>
                    ) : null}
                    <Route
                      path="/login"
                      element={<LoginPage setToken={this.setToken} />}
                    />
                  </Routes>
                </Content>
              </>
            )}
          />
        </Theme>
      </GoogleOAuthProvider>
    );
  }
}

// Create a functional component wrapper
const AppWrapper = props => {
  const navigate = useNavigate();
  return <App {...props} navigate={navigate} />;
};

export default AppWrapper;
