import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Grid,
  Column,
} from '@carbon/react';
import { InfoSection, InfoCard } from '../../components/Info';
import {
  ForecastLightning,
  Application,
  GraphicalDataFlow,
} from '@carbon/react/icons';
import { v4 as uuidv4 } from 'uuid';
import ConversationHistory from './ConversationHistory';
import Assignments from './Assignments';
import Insights from './Insights';
import AIAssistant from './AIAssistant';
import UserFiles from './UserFiles';

const LandingPage = ({ token, user }) => {
  const navigate = useNavigate();

  // Initialize state from localStorage or default values
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  const [conversationId, setConversationId] = useState(() => {
    return localStorage.getItem('conversationId') || uuidv4();
  });

  const [conversationHistory, setConversationHistory] = useState(() => {
    const savedHistory = localStorage.getItem('conversationHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });

  const [input, setInput] = useState(() => {
    return localStorage.getItem('input') || '';
  });

  const [response, setResponse] = useState(() => {
    return localStorage.getItem('response') || '';
  });

  const [placeholderText, setPlaceholderText] = useState(() => {
    return (
      localStorage.getItem('placeholderText') ||
      "Chat with Fresh Focus AI - What's on your mind?"
    );
  });

  // Save activeTab to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  // Save conversationId to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('conversationId', conversationId);
  }, [conversationId]);

  // Save conversationHistory to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(
      'conversationHistory',
      JSON.stringify(conversationHistory)
    );
  }, [conversationHistory]);

  // Save input to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('input', input);
  }, [input]);

  // Save response to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('response', response);
  }, [response]);

  // Save placeholderText to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('placeholderText', placeholderText);
  }, [placeholderText]);

  const handleTabChange = ({ selectedIndex }) => {
    console.info(`Setting active tab to ${selectedIndex}`);
    setActiveTab(selectedIndex);
  };

  const historyTabFocus = () => {
    console.info('History tab selected');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const onRestoreConversation = (conversationId, history) => {
    setConversationHistory(history);
    setConversationId(conversationId);
    setResponse('');
    setInput('');

    if (history && history.length > 0 && history[0].question) {
      const truncatedQuestion =
        history[0].question.length > 100
          ? history[0].question.substring(0, 100) + '...'
          : history[0].question;

      setPlaceholderText(`Continuing conversation: ${truncatedQuestion}`);
    } else {
      setPlaceholderText("Chat with Fresh Focus AI - What's on your mind?");
    }

    setActiveTab(0);
  };

  // Clear stored data when user logs out or token becomes invalid
  useEffect(() => {
    if (!token) {
      // Clear all stored data related to this component
      localStorage.removeItem('activeTab');
      localStorage.removeItem('conversationId');
      localStorage.removeItem('conversationHistory');
      localStorage.removeItem('input');
      localStorage.removeItem('response');
      localStorage.removeItem('placeholderText');

      // Reset state
      setActiveTab(0);
      setConversationId(uuidv4());
      setConversationHistory([]);
      setInput('');
      setResponse('');
      setPlaceholderText("Chat with Fresh Focus AI - What's on your mind?");
    }
  }, [token]);

  return (
    <Grid className="landing-page" fullWidth>
      <Column lg={16} md={8} sm={4} className="landing-page__banner">
        {token ? (
          <div />
        ) : (
          <h1 className="landing-page__heading">Try out Fresh Focus AI</h1>
        )}
      </Column>
      <Column lg={16} md={8} sm={4} className="landing-page__r2">
        <Tabs selectedIndex={activeTab} onChange={handleTabChange}>
          <TabList className="tabs-group" aria-label="Tab navigation">
            <Tab>{token ? 'AI' : 'About'}</Tab>
            <Tab handleTabAnchorFocus={historyTabFocus}>History</Tab>
            <Tab>Insights</Tab>
            <Tab>Tasks</Tab>
            <Tab>Files</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {token ? (
                <Grid className="tabs-group-content">
                  <Column
                    md={8}
                    lg={16}
                    sm={4}
                    className="landing-page__tab-content">
                    <AIAssistant
                      token={token}
                      placeholderText={placeholderText}
                      propInput={input}
                      setInput={setInput}
                      propConversationId={conversationId}
                      setConversationId={setConversationId} // Added this line
                      propConversationHistory={conversationHistory}
                      setConversationHistory={setConversationHistory}
                      propResponse={response}
                      setResponse={setResponse}
                    />
                  </Column>
                </Grid>
              ) : (
                <Grid className="tabs-group-content">
                  <Column
                    md={4}
                    lg={7}
                    sm={4}
                    className="landing-page__tab-content">
                    <h2 className="landing-page__subheading">
                      What is Fresh Focus AI
                    </h2>
                    <p className="landing-page__p">
                      Welcome to FreshFocusAI.com, where we prioritize the 'now'
                      in knowledge. Our friendly AI assistant continually learns
                      from your interactions, diving deep into current data to
                      curate insights that resonate with you, ensuring you're
                      always at the forefront of information. Fresh Focus access
                      is currently by invitation only. You will need to Log in
                      to interact with Fresh Focus AI.
                    </p>
                    <Button className="login-button" onClick={handleLoginClick}>
                      Log in
                    </Button>
                  </Column>
                  <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
                    <img
                      className="landing-page__illo"
                      src={`${process.env.PUBLIC_URL}/aiimageprof2.png`}
                      alt="DLT Logo"
                    />
                  </Column>
                </Grid>
              )}
            </TabPanel>
            <TabPanel>
              <Grid className="tabs-group-content">
                <Column
                  lg={16}
                  md={8}
                  sm={4}
                  className="landing-page__tab-content">
                  <div className="accordion-top-space">
                    Here are your previous conversations. You can view them,
                    restore & continue them, or delete them here.
                  </div>
                  <div className="accordion-top-space">
                    <ConversationHistory
                      token={token}
                      isActive={activeTab === 1}
                      onRestoreConversation={onRestoreConversation}
                    />
                  </div>
                </Column>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid className="tabs-group-content">
                <Column
                  lg={16}
                  md={8}
                  sm={4}
                  className="landing-page__tab-content">
                  <Insights
                    token={token}
                    isActive={activeTab === 2}
                    user={user}
                  />
                </Column>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid className="tabs-group-content">
                <Column
                  lg={16}
                  md={8}
                  sm={4}
                  className="landing-page__tab-content">
                  <Assignments
                    token={token}
                    isActive={activeTab === 3}
                    user={user}
                  />
                </Column>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid className="tabs-group-content">
                <Column
                  lg={16}
                  md={8}
                  sm={4}
                  className="landing-page__tab-content">
                  <UserFiles
                    token={token}
                    isActive={activeTab === 4}
                    user={user}
                  />
                </Column>
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Column>
      {token ? (
        <div />
      ) : (
        <Column lg={16} md={8} sm={4} className="landing-page__r3">
          <InfoSection heading="The Principles">
            <InfoCard
              heading="Personalized Precision"
              body="Dive into an AI experience that truly understands. FreshFocusAI.com learns from each interaction, ensuring content delivered is not just fresh but finely tuned to your unique interests."
              icon={() => <GraphicalDataFlow size={32} />}
            />
            <InfoCard
              heading="Trusted Assistant"
              body="More than just an informational guide, FreshFocusAI.com stands ready to handle your long-running research or content generation needs. Entrust it with extensive tasks, and it diligently works in the background, alerting you when insights are ready for review"
              icon={() => <Application size={32} />}
            />
            <InfoCard
              heading="Real-time Relevance"
              body="Stay a step ahead with our commitment to current content. While others offer static, dated insights, FreshFocusAI.com thrives in the now, ensuring you're always informed with the latest and most pertinent information."
              icon={() => <ForecastLightning size={32} />}
            />
          </InfoSection>
        </Column>
      )}
    </Grid>
  );
};

export default LandingPage;
