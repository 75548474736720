// AIAssistant.js
import React, { useState} from 'react';
import { Accordion, AccordionItem, Button, Tooltip, MultiSelect } from '@carbon/react';
import { ArrowRight, Information } from '@carbon/react/icons';
import ReactMarkdown from 'react-markdown';
import JEmoji from 'emoji-toolkit';
import { v4 as uuidv4 } from 'uuid';

const availableTools = {
  "web_search_google": "Web Search (Google)",
  "news_search_google": "News Search (Google)",
  "web_search_tavily": "Web Search (Tavily)",
  "medical_search_pubmed": "Medical Search (PubMed)",
  "reddit_search": "Reddit Search",
  "jobs_search_google": "Job Search (Google)",
  "web_trends_search_google": "Web Trends Search (Google)",
  "scholarly_search_google": "Scholarly Search (Google)",
  "finance_news_search_yahoo": "Finance News Search (Yahoo)",
  "finance_news_search_google": "Finance News Search (Google)",
  "video_search_youtube": "Video Search (YouTube)",
  "programming_search_stackoverflow": "Programming Search (StackOverflow)",
  "encyclopedia_search_wikipedia": "Encyclopedia Search (Wikipedia)",
  "finance_news_search_alphavantage": "Finance News Search (AlphaVantage)",
  "web_search_duckduckgo": "Web Search (DuckDuckGo)",
  "news_search_duckduckgo": "News Search (DuckDuckGo)",
  "goal_search": "Persistent Search",
};

const AIAssistant = ({
  token,
  placeholderText,
  propInput,
  setInput,
  propConversationId,
  setConversationId,
  propConversationHistory,
  setConversationHistory,
  propResponse,
  setResponse,
}) => {
  // Use the props directly
  const input = propInput;
  const conversationId = propConversationId;
  const conversationHistory = propConversationHistory;
  const response = propResponse;

  // Local state variables specific to this component
  const [selectedModel, setSelectedModel] = useState('gpt-4o-mini'); // Default model
  const [rememberConversation, setRememberConversation] = useState(true);
  const [useTools, setUseTools] = useState(true);
  const [selectedTools, setSelectedTools] = useState([
    'web_search_google',
    'web_search_tavily',
    'news_search_google',
    'reddit_search',
  ]);
  const availableToolsArray = Object.entries(availableTools).map(([id, label]) => ({ id, label }));

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  async function updateConversation(conversationId, messages) {
    console.log("Saving conversation history to elasticsearch");
    const url = `/mlapi/basic/conversation/${encodeURIComponent(conversationId)}/update`;

    // Prepare the payload to include messages and content_type
    const payload = {
      messages: messages,
      content_type: 'ai_conversation',
      model_id: selectedModel,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ensure your token is accessible here
        },
        body: JSON.stringify(payload), // Send the payload as JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error during updateConversation API call:', error);
      throw error; // Re-throw the error for further handling if needed
    }
  }

  const handleClearHistory = () => {
    setConversationHistory([]); // Clears the conversation history
    setResponse(''); // Optionally clear the current response display
    setConversationId(uuidv4()); // Generate a new conversation ID
  };

  function truncateToTwoSentences(text) {
    if (text.length < 420) {
      var hasMarkup = /[<>{}[\];]/;
      var idx = text.search(hasMarkup);
      if (idx > 160) {
        return text.substr(0, idx) + '...';
      }
    }

    const sentences = text.match(/[^.!?]+[.!?]+/g);

    return sentences && sentences.length > 2
      ? sentences.slice(0, 2).join(' ') + '...'
      : text;
  }

  const handleToolChange = (event) => {
    const newSelectedItems = event.selectedItems ? event.selectedItems.map((item) => item.id) : [];

    setSelectedTools((prevSelectedTools) => {
      if (
        !Array.isArray(prevSelectedTools) ||
        JSON.stringify(prevSelectedTools) !== JSON.stringify(newSelectedItems)
      ) {
        return newSelectedItems;
      }
      return prevSelectedTools;
    });
  };

  const handleSubmit = async () => {
    const now = new Date().toISOString(); // Gets the current date and time in ISO 8601 format
    const prompt = `(Please respond in Markdown format. The date and time is currently: ${now}) My question: ${input}`;
    const roleForAIResponse = selectedModel === 'gemini-pro' ? 'model' : 'system';
    const messages = conversationHistory.flatMap((interaction) => [
      { role: 'user', content: interaction.question },
      { role: roleForAIResponse, content: interaction.answer },
    ]);
    messages.push({ role: 'user', content: prompt });

    // Build the tools query parameters with repeated `tools=` syntax
    const toolsQueryParams = selectedTools
      .map((tool) => `tools=${encodeURIComponent(tool)}`)
      .join('&');

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(messages),
    };

    setResponse(`\n  \n**${input}**\n  \n  `);
    setInput('');

    try {
      const response = await fetch(
        `/mlapi/basic/${
          useTools
            ? `stream-llm-tools?model=${selectedModel}&${toolsQueryParams}`
            : `stream-llm?model=${selectedModel}`
        }`,
        requestOptions
      );

      if (!response.body) {
        throw new Error('Response body is not readable');
      }

      const reader = response.body.getReader();
      let receivedLength = 0;
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        receivedLength += value.length;

        const textChunk = new TextDecoder('utf-8').decode(value, {
          stream: true,
        });
        setResponse((prevResponse) => prevResponse + textChunk);
      }

      let chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      const result = new TextDecoder('utf-8').decode(chunksAll);
      setResponse(`\n  \n**${input}**\n  \n  ${result}`);

        setConversationHistory((prevHistory) => [
          ...prevHistory,
          { question: input, answer: result },
        ]);

      if (rememberConversation) {
        const latest_messages = [
          { role: 'user', content: input },
          { role: 'system', content: result },
        ];
        try {
          const response = await updateConversation(conversationId, latest_messages);
          console.log('Response from update conversation: ', response);
        } catch (error) {
          console.error('Error during API call:', error);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formattedResponse = JEmoji.shortnameToUnicode(response);

  return (
    <>
      <div className="use-tools-container">
        <select
          className="llm-select"
          value={selectedModel}
          onChange={handleModelChange}>
          <option value="gpt-4">GPT-4</option>
          <option value="gpt-4o">GPT-4o</option>
          <option value="gpt-4o-mini">gpt-4o-mini</option>
          <option value="gpt-4-turbo">GPT-4-Turbo</option>
          <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
          <option value="gpt-o1-preview">gpt-o1-preview (no search tools)</option>
          <option value="gpt-o1-mini">gpt-o1-mini (no search tools)</option>
          <option value="gemini-pro">Gemini Pro</option>
          <option value="gemini-1.5-flash-latest">Gemini Flash</option>
          <option value="claude-3-opus-20240229">
            Anthropic Claude 3 Opus (best)
          </option>
          <option value="claude-3-sonnet-20240229">
            Anthropic Claude 3 Sonnet (medium)
          </option>
          <option value="claude-3-haiku-20240307">
            Anthropic Claude 3 Haiku (fast)
          </option>
          <option value="claude-2.1">Anthropic Claude 2</option>
          <option value="llama-3">Llama 3.1</option>
          <option value="cohere_command">
            Cohere Command (fast no search tools)
          </option>
          <option value="cohere_command-r">Cohere Command R (medium)</option>
          <option value="cohere_command-r-plus">
            Cohere Command R Plus (best)
          </option>
          <option value="cohere_command-nightly">
            Cohere Command Nightly (latest)
          </option>
        </select>
        &nbsp;&nbsp;
        <input
          type="checkbox"
          id="useTools"
          name="useTools"
          checked={useTools}
          value={useTools}
          onChange={(e) => setUseTools(e.target.checked)}
        />
        <label htmlFor="useTools">Use Search Tools</label>
        &nbsp;
        <Tooltip
          label="Allow FreshFocus AI to use any tools it finds appropriate for your request to search for current information. You can select from one or more of the tools it can use if you want more control."
          align="bottom"
        >
          <button className="sb-tooltip-trigger" type="button">
            <Information />
          </button>
        </Tooltip>
        {useTools && (
          <MultiSelect
            className="tools-select"
            id="multi-select-tools"
            label="Select Search Tools"
            titleText=""
            items={availableToolsArray}
            itemToString={(item) => (item ? item.label : '')}
            onChange={handleToolChange}
            selectedItems={availableToolsArray.filter((item) =>
              selectedTools.includes(item.id)
            )}
            size="sm"
          />
        )}
      </div>

      <textarea
        className="llm-request-textarea"
        placeholder={placeholderText}
        value={input}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          // Check for Enter key without Shift
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent newline
            handleSubmit(); // Call your submit function
          }
        }}
      />
        <input
          type="checkbox"
          id="rememberConversation"
          name="rememberConversation"
          checked={rememberConversation}
          onChange={(e) => setRememberConversation(e.target.checked)}
        />
        <label htmlFor="rememberConversation">Remember</label>
        &nbsp;
        <Tooltip
          label="Select Remember to save the conversation so you may review or continue it in the future. It will also allow Fresh Focus AI to provide more personalized and relevant insights based on this dialogue. You can always review, delete, and continue any saved conversations from the History tab."
          align="bottom"
        >
          <button className="sb-tooltip-trigger" type="button">
            <Information />
          </button>
        </Tooltip>
        <br/>
      <Button className="llm-submit" kind="primary" size="small" onClick={handleSubmit}>
        Ask <ArrowRight />
      </Button>
      &nbsp;
      <Button
        className="llm-submit"
        kind="secondary"
        size="small"
        onClick={handleClearHistory}
      >
        Clear
      </Button>
      &nbsp;
      <Tooltip
        label="Use Clear to start a new conversation topic. If Remember is checked, it will still be saved so you can review or resume it in the future."
        align="bottom"
      >
        <button className="sb-tooltip-trigger" type="button">
          <Information />
        </button>
      </Tooltip>
      <div className="llm-response-area">
        {formattedResponse && <ReactMarkdown>{formattedResponse}</ReactMarkdown>}
        {conversationHistory.length === 0 && !formattedResponse && (
          <img
            className="landing-page__illo"
            src={`${process.env.PUBLIC_URL}/aiimageprof2.png`}
            alt="DLT Logo"
          />
        )}
      </div>
      <div className="accordion-top-space">
        {conversationHistory.length > 0 && (
          <>
            <h4>Conversation History</h4>
            <Accordion>
              {conversationHistory.map((interaction, index) => (
                <AccordionItem
                  title={truncateToTwoSentences(`Q: ${interaction.question}`)}
                  key={index}
                >
                  <div className="llm-response-area">
                    <ReactMarkdown>{interaction.answer}</ReactMarkdown>
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        )}
      </div>
    </>
  );
};

export default AIAssistant;
