// UserFiles.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  DataTable,
  Button,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Tooltip,
  InlineLoading,
  Loading, // Imported Loading component
} from '@carbon/react';

import {
  DocumentDownload,
  DocumentView,
  Upload,
  TrashCan,
  Renew,
  Information,
} from '@carbon/react/icons';

import ReactMarkdown from 'react-markdown';

import { v4 as uuidv4 } from 'uuid';

const UserFiles = ({ token, isActive, user }) => {
  const [userFileList, setUserFileList] = useState([]);

  // Initialize selectedFile from localStorage
  const [selectedFile, setSelectedFile] = useState(() => {
    return localStorage.getItem('userFiles_selectedFile') || null;
  });

  // Initialize isModalOpen from localStorage
  const [isModalOpen, setModalOpen] = useState(() => {
    const savedIsModalOpen = localStorage.getItem('userFiles_isModalOpen');
    return savedIsModalOpen ? JSON.parse(savedIsModalOpen) : false;
  });

  // Initialize fileContent from localStorage
  const [fileContent, setFileContent] = useState(() => {
    return localStorage.getItem('userFiles_fileContent') || null;
  });

  const [isLoading, setLoading] = useState(false);
  const [loadingFileList, setLoadingFileList] = useState(false); // New state for loading file list
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobile, setIsMobile] = useState(false); // Detect mobile devices
  const fileInputRef = useRef(null);

  // Save selectedFile to localStorage whenever it changes
  useEffect(() => {
    if (selectedFile !== null) {
      localStorage.setItem('userFiles_selectedFile', selectedFile);
    } else {
      localStorage.removeItem('userFiles_selectedFile');
    }
  }, [selectedFile]);

  // Save isModalOpen to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('userFiles_isModalOpen', JSON.stringify(isModalOpen));
  }, [isModalOpen]);

  // Save fileContent to localStorage whenever it changes
  useEffect(() => {
    if (fileContent !== null) {
      localStorage.setItem('userFiles_fileContent', fileContent);
    } else {
      localStorage.removeItem('userFiles_fileContent');
    }
  }, [fileContent]);

  // Detect screen size to adjust for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define handleCloseModal before fetchUserFileList
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setSelectedFile(null);
    setFileContent(null);
  }, []);

  // Wrap fetchUserFileList in useCallback and move it before the useEffect that uses it
  const fetchUserFileList = useCallback(async () => {
    const url = `mlapi/premium/task/data/list_detail/files`;
    try {
      setLoadingFileList(true); // Start loading file list
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUserFileList(data.reverse());

      // Check if selectedFile is still in the list
      if (selectedFile) {
        const fileExists = data.some((file) => file.name === selectedFile);
        if (!fileExists) {
          // File no longer exists, close modal
          handleCloseModal();
        }
      }
    } catch (error) {
      console.error('Error fetching user files:', error);
    } finally {
      setLoadingFileList(false); // Stop loading file list
    }
  }, [token, selectedFile, handleCloseModal]);

  // Fetch file list on component mount or when token or isActive changes
  useEffect(() => {
    if (token && isActive) {
      fetchUserFileList();
    } else {
      setUserFileList([]);
    }
  }, [token, isActive, fetchUserFileList]);

  // Wrap fetchFileContent in useCallback and move it before the useEffect that uses it
  const fetchFileContent = useCallback(
    async (fileName) => {
      setLoading(true);
      const url = `mlapi/premium/task/data/get_binary/files/${fileName}`;
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch file content. Status: ${response.status}`);
        }
        const content = await response.text();
        setFileContent(content);
      } catch (error) {
        console.error('Error fetching file content:', error);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  // Fetch file content if modal is open and file content is not loaded
  useEffect(() => {
    if (isModalOpen && selectedFile && !fileContent) {
      fetchFileContent(selectedFile);
    }
  }, [isModalOpen, selectedFile, fileContent, fetchFileContent]);

  // Clear saved state when token becomes falsy (user logs out)
  useEffect(() => {
    if (!token) {
      // User logged out, clear saved state
      localStorage.removeItem('userFiles_selectedFile');
      localStorage.removeItem('userFiles_isModalOpen');
      localStorage.removeItem('userFiles_fileContent');
      setSelectedFile(null);
      setModalOpen(false);
      setFileContent(null);
      setUserFileList([]);
    }
  }, [token]);

  const handleDownload = async (fileName) => {
    const url = `mlapi/premium/task/data/get_binary/files/${fileName}`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const url = `mlapi/premium/task/data/upload/files/${file.name}`;
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        await fetchUserFileList(); // Refresh file list after upload
      } else {
        console.error('File upload failed.');
      }
    } catch (error) {
      console.error('Error uploading the file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewFile = (fileName) => {
    // Reset file content before fetching
    setFileContent(null);
    setSelectedFile(fileName);
    setModalOpen(true);
    // Fetch file content will be triggered by useEffect
  };

  const handleDelete = async (fileName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${fileName}?`
    );
    if (!confirmDelete) return;

    const url = `mlapi/premium/task/data/delete/files/${fileName}`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete the file. Status: ${response.status}`);
      }

      // If the deleted file is the selectedFile, close the modal
      if (fileName === selectedFile) {
        handleCloseModal();
      }

      // Refresh the file list after successful deletion
      await fetchUserFileList();
      alert(`${fileName} has been deleted successfully.`);
    } catch (error) {
      console.error('Error deleting the file:', error);
    }
  };

  // Filter the rows based on the search term
  const filteredRows = userFileList.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const rows = filteredRows.map((file) => ({
    id: uuidv4(),
    file_name: file.name,
    created_at:
      file.created_at === 'Unknown'
        ? 'Unknown'
        : new Date(file.created_at).toLocaleDateString(),
    size: (file.size / 1024).toFixed(2),
    expires_at:
      file.expires_at === 'No expiration'
        ? 'No Expiration'
        : new Date(file.expires_at).toLocaleDateString(),
  }));

  const triggerFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRefresh = async () => {
    await fetchUserFileList();
    alert('File list refreshed');
  };

  return (
    <>
      Upload files for AI to analyze or tell it to save information in a file you can view or download from here.
      <Tooltip
        label="When using AI, check Use Search Tools to allow it to save and load data from your files. For example: Get today's news about XYZ and save it to a file with today's date-XYZ"
        align="bottom-right"
      >
        <button className="sb-tooltip-trigger" type="button">
          <Information />
        </button>
      </Tooltip>
      {loadingFileList ? (
        // Display loading indicator while loading file list
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: '3em', // Adds approximately three blank lines of space
        }}>
          <Loading description="Loading files..." withOverlay={false} />
        </div>
      ) : (
        <TableContainer>
          <TableToolbar>
            <TableToolbarContent>
              <TableToolbarSearch
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
              <Button
                renderIcon={Upload}
                iconDescription="Upload"
                onClick={triggerFileUpload}
              >
                Upload File
              </Button>
              <Button
                renderIcon={Renew}
                iconDescription="Refresh"
                onClick={handleRefresh}
              >
                Refresh List
              </Button>
            </TableToolbarContent>
          </TableToolbar>
          <DataTable
            rows={rows}
            headers={[
              { key: 'file_name', header: 'File Name' },
              !isMobile && { key: 'created_at', header: 'Created At' },
              !isMobile && { key: 'expires_at', header: 'Expires At' },
              { key: 'actions', header: 'Actions' },
            ].filter(Boolean)}
            render={({ rows, headers, getHeaderProps, getRowProps }) => (
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })} key={header.key}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow {...getRowProps({ row })} key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>
                          {cell.info.header === 'actions' ? (
                            <>
                              <Button
                                renderIcon={DocumentDownload}
                                hasIconOnly
                                tooltipAlignment="center"
                                iconDescription="Download"
                                onClick={() =>
                                  handleDownload(row.cells[0].value)
                                }
                              />
                              <Button
                                renderIcon={DocumentView}
                                hasIconOnly
                                tooltipAlignment="center"
                                iconDescription="View"
                                onClick={() =>
                                  handleViewFile(row.cells[0].value)
                                }
                              />
                              <Button
                                renderIcon={TrashCan}
                                hasIconOnly
                                tooltipAlignment="center"
                                iconDescription="Delete"
                                onClick={() =>
                                  handleDelete(row.cells[0].value)
                                }
                              />
                            </>
                          ) : (
                            cell.value
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          />
        </TableContainer>
      )}

      <Modal
        open={isModalOpen}
        modalHeading={`Viewing ${selectedFile}`}
        onRequestClose={handleCloseModal}
        passiveModal
      >
        {isLoading ? (
          // Display the loading indicator inside the modal while loading
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <InlineLoading description="Loading file content..." />
          </div>
        ) : selectedFile && selectedFile.endsWith('.md') ? (
          <ReactMarkdown>{fileContent}</ReactMarkdown>
        ) : (
          <pre>{fileContent}</pre>
        )}
      </Modal>
    </>
  );
};

export default UserFiles;
