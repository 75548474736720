import React, { useState, useEffect } from 'react';
import { Button, Checkbox, MultiSelect } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const EvernoteIntegrationPage = () => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [notebooks, setNotebooks] = useState([]);
  const [selectedNotebooks, setSelectedNotebooks] = useState([]);

  useEffect(() => {
    // Check if the user is already connected to Evernote
    fetch('/api/evernote-integration/status')
      .then((response) => response.json())
      .then((data) => {
        setIsConnected(data.isConnected);
        if (data.isConnected) {
          setNotebooks(data.notebooks);
          setSelectedNotebooks(data.selectedNotebooks || []);
        }
      })
      .catch((error) => {
        console.error('Error fetching Evernote status:', error);
      });
  }, []);

  const handleConnect = () => {
    // Redirect to the backend endpoint that initiates the OAuth flow
    window.location.href = '/api/evernote-integration/connect';
  };

  const handleDisconnect = () => {
    if (
      window.confirm(
        'Are you sure you want to disconnect Evernote? This will revoke access and remove all synced data.'
      )
    ) {
      fetch('/api/evernote-integration/disconnect', {
        method: 'POST',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to disconnect Evernote.');
          }
          alert('Evernote has been disconnected.');
          setIsConnected(false);
          setNotebooks([]);
          setSelectedNotebooks([]);
        })
        .catch((error) => {
          console.error('Error disconnecting Evernote:', error);
          alert('An error occurred while disconnecting Evernote.');
        });
    }
  };

  const handleSaveSettings = () => {
    // Save the selected notebooks
    fetch('/api/evernote-integration/save-settings', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ selectedNotebooks }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to save Evernote settings.');
        }
        alert('Evernote settings saved successfully.');
        navigate('/');
      })
      .catch((error) => {
        console.error('Error saving Evernote settings:', error);
        alert('An error occurred while saving your Evernote settings.');
      });
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="evernote-integration-page">
      <div className="evernote-integration-container">
        <h1>Evernote Integration</h1>
        {!isConnected ? (
          <>
            <p>
              Connect your Evernote account to allow Fresh Focus AI to read and create notes on your behalf.
              This enables the AI assistant to access your Evernote notes and enhance your experience.
            </p>
            <Button kind="primary" onClick={handleConnect}>
              Connect with Evernote
            </Button>
          </>
        ) : (
          <>
            <p>
              Your Evernote account is connected. Select the notebooks you want to grant access to.
            </p>
            <h2>Select Notebooks</h2>
            <MultiSelect
              id="notebooks-select"
              label="Available Notebooks"
              items={notebooks}
              itemToString={(item) => (item ? item.name : '')}
              initialSelectedItems={selectedNotebooks}
              onChange={({ selectedItems }) => setSelectedNotebooks(selectedItems)}
            />
            <Checkbox
              id="create-notes"
              labelText="Allow Fresh Focus AI to create new notes in Evernote"
              checked={selectedNotebooks.allowCreateNotes}
              onChange={(checked) =>
                setSelectedNotebooks((prev) => ({
                  ...prev,
                  allowCreateNotes: checked,
                }))
              }
            />
            <div className="button-group" style={{ marginTop: '2rem' }}>
              <Button kind="danger" onClick={handleDisconnect}>
                Disconnect Evernote
              </Button>
              <Button kind="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button kind="primary" onClick={handleSaveSettings}>
                Save Settings
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EvernoteIntegrationPage;
