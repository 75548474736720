import React from 'react';
import { Button } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const PrivacyPage = () => {
  const navigate = useNavigate();

  const handleDeleteHistory = () => {
    if (window.confirm('Are you sure you want to delete your conversation history? This action cannot be undone.')) {
      fetch('/mlapi/basic/conversation/delete-history', {
        method: 'DELETE',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to delete conversation history.');
          }
          alert('Your conversation history has been deleted successfully.');
        })
        .catch((error) => {
          console.error('Error deleting conversation history:', error);
          alert('An error occurred while deleting your conversation history.');
        });
    }
  };

  const handleDeleteAllData = () => {
    if (window.confirm('Are you sure you want to delete all your user data? This action cannot be undone.')) {
      fetch('/mlapi/basic/user/delete-all-data', {
        method: 'DELETE',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to delete user data.');
          }
          alert('All your user data has been deleted successfully.');
          // Optionally, log the user out or redirect
          navigate('/login');
        })
        .catch((error) => {
          console.error('Error deleting user data:', error);
          alert('An error occurred while deleting your user data.');
        });
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="privacy-page">
      <div className="privacy-container">
        <h1>Privacy</h1>
        <p>
          At Fresh Focus AI, we are committed to protecting your privacy. You have full control over your data and can manage it below.
        </p>
        <h2>Manage Your Data</h2>
        <p>
          You can delete your conversation history or all of your user data at any time. Please note that these actions are irreversible.
        </p>
        <div className="button-group" style={{ marginTop: '1rem' }}>
          <Button kind="danger" onClick={handleDeleteHistory}>
            Delete Conversation History
          </Button>
          <Button kind="danger--primary" onClick={handleDeleteAllData}>
            Delete All User Data
          </Button>
        </div>
        <h2>Privacy Policy</h2>
        <p>
          Fresh Focus AI collects and stores your conversation history to enhance your experience by providing personalized insights and recommendations. We do not share your personal data with third parties without your consent.
        </p>
        <p>
          For more details, please read our <a href="/privacy-policy.html">Privacy Policy</a>.
        </p>
        <div className="button-group" style={{ marginTop: '2rem' }}>
          <Button kind="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button kind="primary" onClick={handleCancel}>
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
