import React, { useState, useEffect } from 'react';
import { Button, TextInput } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const SmsIntegrationPage = ({ token, user }) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');

  // Fetch the phone number if it's saved from previous
  useEffect(() => {
    fetch('mlapi/premium/task/data/get/configuration/phone_number', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.status !== 200) {
          // No phone number saved; set to blank
          return '';
        }
        if (!response.ok) {
          throw new Error('Failed to fetch phone number.');
        }
        return response.text();
      })
      .then(data => setPhoneNumber(data.replace(/^"|"$/g, ''))) // Trim double quotes
      .catch(error => console.error('Error fetching phone number:', error));
  }, [token]);

  const handleSaveSettings = () => {
    // Validate phone number
    if (!phoneNumber) {
      alert('Please enter your phone number.');
      return;
    }

    // Save the phone number
    fetch('mlapi/premium/task/data/save/configuration/phone_number', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        value: phoneNumber,
        ttl: 0,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to save phone number.');
        }
        alert('Phone number saved successfully.');
        navigate('/');
      })
      .catch(error => {
        console.error('Error saving phone number:', error);
        alert('An error occurred while saving your phone number.');
      });
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="sms-integration-page">
      <div className="sms-integration-container">
        <h1>SMS Integration</h1>
        <p>
          To enable Fresh Focus AI to send SMS messages to you, please enter
          your phone number below. This will be the only number used to send
          messages.
        </p>

        <TextInput
          id="phone-number"
          labelText="Your Phone Number"
          placeholder="e.g., +1234567890"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          required
        />

        <p style={{ marginTop: '1rem' }}>
          By providing your phone number, you authorize Fresh Focus AI to send
          text messages to this number when you request them.
        </p>

        <div className="button-group" style={{ marginTop: '2rem' }}>
          <Button kind="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button kind="primary" onClick={handleSaveSettings}>
            Save Phone Number
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SmsIntegrationPage;
