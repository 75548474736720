import React, { useState, useEffect } from 'react';
import {
  Button,
  TextArea,
  Form,
} from '@carbon/react';
import { useNavigate } from 'react-router-dom';
import yaml from 'js-yaml';

const SettingsPage = () => {
  const navigate = useNavigate();
  const [settingsData, setSettingsData] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch initial settings from the API
    fetch('/mlapi/basic/settings')
      .then((response) => response.json())
      .then((data) => {
        // Convert JSON to YAML
        const yamlData = yaml.dump(data);
        setSettingsData(yamlData);
      })
      .catch((error) => {
        console.error('Error fetching settings:', error);
        alert('An error occurred while fetching settings.');
      });
  }, []);

  const handleChange = (e) => {
    setSettingsData(e.target.value);
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Try to parse the YAML to verify it's valid
    try {
      const parsedData = yaml.load(settingsData);

      // Make a POST request to your API endpoint
      fetch('/mlapi/basic/settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(parsedData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to save settings.');
          }
          return response.json();
        })
        .then((data) => {
          // Handle success response
          alert('Settings updated successfully!');
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('An error occurred while updating settings.');
        });
    } catch (e) {
      // If parsing fails, display an error message
      console.error('YAML parsing error:', e);
      setError('Invalid YAML format: ' + e.message);
    }
  };

  const handleResetToDefault = () => {
    // Fetch default settings from the API
    fetch('/mlapi/basic/settings/default')
      .then((response) => response.json())
      .then((data) => {
        // Convert JSON to YAML
        const yamlData = yaml.dump(data);
        setSettingsData(yamlData);
        setError('');
      })
      .catch((error) => {
        console.error('Error fetching default settings:', error);
        alert('An error occurred while fetching default settings.');
      });
  };

  const handleCancel = () => {
    // Optionally, reset to the initial settings or navigate away
    navigate('/');
  };

  return (
    <div className="settings-page">
      <div className="settings-container">
        <h1>Settings</h1>
        <Form onSubmit={handleSubmit}>
          <div className="form-item">
            <TextArea
              id="settingsTextArea"
              labelText="Settings (YAML Format)"
              value={settingsData}
              onChange={handleChange}
              rows={20}
              required
            />
          </div>
          {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
          <div className="button-group" style={{ marginTop: '1rem' }}>
            <Button type="submit">Save Settings</Button>
            <Button kind="secondary" onClick={handleResetToDefault}>
              Reset to Default
            </Button>
            <Button kind="tertiary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SettingsPage;
