import { Button } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const EmailIntegrationPage = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="email-integration-page">
      <div className="email-integration-container">
        <h1>Email Integration</h1>
        <p>
          Fresh Focus AI is enabled to send emails to your email address, that you use to login, if 
          you explicitly ask it to.  It will not send emails to others or any mailing lists.
        </p>
        <p>
          For instance, if you ask "Please give me today's news about the Nasdaq and send it to my email",
          then it will send the information to your email address.
        </p>

        <p style={{ marginTop: '1rem' }}>
        </p>

        <div className="button-group" style={{ marginTop: '2rem' }}>
          <Button kind="primary" onClick={handleCancel}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailIntegrationPage;
